import {
  Kegs10LT,
  Kegs10LTModal,
  Kegs30LT,
  Kegs30LTModal,
  Kegs50LT,
  Kegs50LTModal,
  Kegs20LT,
  Kegs20LTModal,
} from "../images/productos/kegs-cervezas";

import { PDF1, PDF2, PDF3, PDF4 } from "../../static/13-kegs/index"

const kegsCervezas = [
  {
    size: 10,
    name: "Keg acero inoxidable 10LT",
    pdfDownload: PDF1,
    src: Kegs10LT,
    srcModal: Kegs10LTModal,
    color: {
      white: false,
      green: false,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    size: 20,
    name: "Keg acero inoxidable 20LT",
    pdfDownload: PDF4,
    src: Kegs20LT,
    srcModal: Kegs20LTModal,
    color: {
      white: false,
      green: false,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    size: 30,
    name: "Keg acero inoxidable 30LT",
    pdfDownload: PDF2,
    src: Kegs30LT,
    srcModal: Kegs30LTModal,
    color: {
      white: false,
      green: false,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },
  {
    size: 50,
    name: "Keg acero inoxidable 50LT",
    pdfDownload: PDF3,
    src: Kegs50LT,
    srcModal: Kegs50LTModal,
    color: {
      white: false,
      green: false,
      darkGreen: false,
      yellow: false,
      lightGreen: false,
    },
  },

];
export default kegsCervezas;
