import React from "react";

import Products from "../products";
import ProductCard from "../../components/ProductCards/ProductCards";
import kegsCervezas from "../../data/kegs_cervesas";
import SEO from "../../components/SEO/Seo";

const KegsCervezas = () => {
  return (
    <>
      <SEO
        title="Blue Sky SA - Kegs Barriles Acero inoxidable"
        keywords="Blue Sky SA , Botellas , Botellas de vidrio , Tapas rosca , Corchos , Tapones , Argentina , Mendoza , Tapas Pilfer , Screw Caps , Aluminio.  ,Blue Sky SA , Procesos adicionales , Satinado de botellas. Arenado , Pintado de botellas. Colores varios. Botellas coloreadas , Argentina."
        description="BlueSky SA - Keg - Kegs - Barriles de acero inoxidable para cerveza - 50 Litros - 30 Litros - 10 Litros "
      />
      <div>
        <Products subtitle="Kegs Cervezas">
          <ProductCard data={kegsCervezas} />
        </Products>
      </div>
    </>
  );
};

export default KegsCervezas;
